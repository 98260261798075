import { PreloadedState, combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import navigationReducer from './features/navigation/navigationSlice';
import settlementReducer from './features/settlement/settlementSlice';
import inboxReducer from './features/inbox/inboxSlice';
import { rtkQueryErrorLogger } from './middleware/errorMiddleware';
import { twApi } from './api/api';
import invoiceReducer from './features/invoice/invoiceSlice';
import activeTripsReducer from './features/activeTrips/activeTripsSlice';
import publicLoadReducer from './features/publicLoad/publicLoadSlice';
import calendarReducer from './features/calendar/calendarSlice';
import ordersReducer from './features/orders/ordersSlice';

const rootReducer = combineReducers({
  // slice for RTK query
  [twApi.reducerPath]: twApi.reducer,

  // other slice
  navigation: navigationReducer,
  settlement: settlementReducer,
  invoice: invoiceReducer,
  activeTrips: activeTripsReducer,
  inbox: inboxReducer,
  publicLoad: publicLoadReducer,
  calendar: calendarReducer,
  orders: ordersReducer
});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const setupStore = (preloadedState?: PreloadedState<RootState>) =>
  configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false
      })
        .concat(twApi.middleware)
        .concat(rtkQueryErrorLogger)
  });

export const store = setupStore();

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
