import { USER_ROLE } from '../providers/User/User.model';

function startIntercom(): void {
  window.zE('webWidget', 'hide');
  window.Intercom('boot', { app_id: 'vdunphop' });
}

function startZendesk(): void {
  window.Intercom('shutdown');
  window.zE('webWidget', 'show');
}

export function hideWidget(): void {
  window.Intercom('shutdown');
  window.zE('webWidget', 'hide');
}

export function showWidget(enableIntercomFlag: boolean, userRole: USER_ROLE | undefined): void {
  if (!userRole || userRole === 'CUSTOMER_CONTACT') return;
  enableIntercomFlag ? startIntercom() : startZendesk();
}
